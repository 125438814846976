// PRINT ONLY file
// custom print view for MPP compare tool
// these load in a custom template
// associated sass file:
// themes/estee_base/scss/drupal_modules/esteelauder_product/_pc_mpp_compare.scss

.products-compare {
  //background: $color-white;
  -webkit-print-color-adjust: exact;
  #toolbar,
  .page-wrapper,
  .back-to-top {
    display: none;
  }
  #colorbox,
  #cboxWrapper,
  #cboxContent,
  #cboxLoadedContent {
    width: 670px !important;
    height: 1000px !important;
    @include swap_direction(padding, 0 !important);
    @include swap_direction(margin, 0 !important);
    #{$ldirection}: 0 !important;
  }
  table.mpp-compare__table {
    background: $color-white;
    color: $color-navy;
    width: 670px;
    height: 1000px;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0);
    #{$ldirection}: 0;
    font-size: 12px !important;
    .mpp-compare__print {
      display: none;
    }
    a[href]:after,
    a[href]:after {
      content: ' (' attr(href) ')';
      display: none !important;
    }
    .selectBox,
    .js-add-to-cart {
      display: none !important;
    }
    .mpp-compare__label {
      font-size: 18px !important;
      color: $color-navy !important;
    }
    td {
      @include swap_direction(border, 1px solid $color-light-gray);
      &:first-child {
        @include swap_direction(padding, 0);
        @include swap_direction(margin, 0);
      }
      @include swap_direction(padding, 5px 0 !important);
      @include swap_direction(margin, 0);
    }
    // border
    tr:first-child td {
      border-top: 0;
      @include swap_direction(padding, 0 15px 15px 15px);
    }
    tr:last-child td {
      border-bottom: 0;
    }
    tr td:first-child {
      border-#{$rdirection}: 0;
      border-#{$ldirection}: 0;
    }
    tr td:nth-child(2) {
      border-#{$ldirection}: 0;
    }
    tr td:last-child {
      border-#{$rdirection}: 0;
    }
    // widths
    th:nth-of-type(1) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      max-width: 50px;
    }
    th:nth-of-type(2) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      max-width: 170px;
    }
    th:nth-of-type(3) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      max-width: 170px;
    }
    th:nth-of-type(4) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      max-width: 170px;
    }
    .mpp-compare__header {
      font-size: 14px;
    }
    .mpp-compare__cart {
      display: none;
    }
    .mpp-compare__swatch-list-container {
      display: none;
    }
    .mpp-compare__reviews {
      display: none;
    }
    .mpp-compare__cart-print {
      display: table-row;
      td {
        .mpp-compare__cart-print-shade {
          @include pie-clearfix;
          clear: both;
          width: 100%;
        }
        .mpp-compare__shade {
          float: $ldirection;
          padding-top: 8px;
        }
        .mpp-compare__swatch-container {
          float: $ldirection;
          @include swap_direction(margin, 0 10px 0 0);
          @include swap_direction(padding, 0);
        }
      }
    }
  }
}
