.mpp {
  // things to hide
  .compare-checkbox-wrapper,
  .product_brief__buttons {
    display: none;
  }
  .mpp__product {
    width: 226px;
    margin-#{$rdirection}: 1em;
    position: relative;
  }
  .product_brief__image {
    background: inherit;
    background-image: inherit;
    -webkit-print-color-adjust: exact;
    width: 226px;
    height: 300px;
    background-position: -34px -34px;
  }
  .product_brief__buttons-container {
    position: relative;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: '';
  }
  abbr[title]:after {
    content: '';
  }
}
