/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request: h5bp.com/r
   ========================================================================== */

$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

* {
  background: transparent;
  //color: #000 !important; /* Black prints faster: h5bp.com/s */
  box-shadow: none !important;
  text-shadow: none !important;
}

a,
a:visited {
  text-decoration: underline;
}

a[href]:after {
  content: ' (' attr(href) ')';
}

abbr[title]:after {
  content: ' (' attr(title) ')';
}

/*
 * Don't show links that are fragment identifiers,
 * or use the `javascript:` pseudo protocol
 */

a[href^='#']:after,
a[href^='/']:after,
a[href^='javascript:']:after {
  content: '';
}

pre,
blockquote {
  @include swap_direction(border, 1px solid #999);
  page-break-inside: avoid;
}

thead {
  display: table-header-group; /* h5bp.com/t */
}

tr,
img {
  page-break-inside: avoid;
}

img {
  max-width: 100% !important;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

.page-wrapper {
  padding-top: 0;
}

.main-content:before {
  content: ' ';
  display: block;
  width: 272px;
  height: 35px;
  @include swap_direction(margin, 0 auto);
  position: relative;
  background-image: url('/media/logos/elc-logo.png');
  margin-bottom: 20px;
}

.home-formatter {
  margin-top: 0;
}

/*
 * Global elements to hide
 */
.page-navigation,
.page-utilities,
.back-to-top,
.footer-change-location,
.footer-signup-email,
.footer-signup-sms,
.footer-legal,
.page-header,
.page-footer,
.page-sticky-footer,
.page-navigation__menu-toggle,
#togglemenus-collapsed,
.home-formatter__scroller {
  display: none;
}

.video_youtube__content,
.parallax__container {
  background: inherit;
  background-image: inherit;
  -webkit-print-color-adjust: exact;
}
