.spp-product__how-to-use .spp__steps {
  max-width: 100%;
}

.spp-product__reviews {
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: '';
  }
  abbr[title]:after {
    content: '';
  }
}

.product-full {
  .swatch--1,
  .swatch--2,
  .swatch--3,
  .swatch--4,
  .swatch--5 {
    background: inherit;
    background-color: inherit;
    -webkit-print-color-adjust: exact;
  }
  .ul.shade-list li {
    @include swap_direction(border, $color-red solid 1px);
  }
  .product-full__images .product-full__image img {
    @include swap_direction(margin, 0 0 0 0);
    display: block;
    width: auto;
    height: auto;
    max-height: 600px;
  }
}
// things to hide
#BVRRDisplayContentSortID,
.spp-product__mini-bag,
.slick-dots {
  display: none;
}
